<template>
  <div class="ou-overview">
    <template v-if="is_entry">
      <template v-if="ous && ous.length === 0">
        <div>
          <b-alert variant="danger" class="text-center" show>
            {{ $t('views.org.ou.ou_not_exist', {name: $route.params.ou}) }}
          </b-alert>
        </div>
      </template>
      <Loading v-else :data="[ou, mgrs_by_login_name]">
        <template v-if="ou && mgrs_by_login_name">
          <b-row>
            <b-col>
              <h1>{{ ou.name }} ({{ ou.short_name }}) <small
                  class="text-muted">{{ $tc('system.organizational_unit', 1) }}</small></h1>
              <h3 class="text-muted">
                <netvs-icon icon="org_types"/>
                {{ outypes_by_shortname[ou.type].name }}
              </h3>
              <br/>
            </b-col>
            <b-col lg="2">
              <b-button-group class="d-flex">
                <b-button v-if="check_rights($store.state, 'org.rw_oe')" variant="outline-danger"
                          :id="'delete-ou-' + ou.short_name"
                          @click="delete_ou(ou)" class="mt-3 shadow">
                  <netvs-icon icon="delete"/>
                </b-button>
                <b-tooltip :target="'delete-ou-' +  ou.short_name" triggers="hover"
                           variant="danger" placement="bottom">
                  {{ $t('system.delete') }}
                </b-tooltip>
                <b-button variant="outline-primary" :id="'edit-ou-' + ou.short_name"
                          @click="edit_ou(ou)" class="mt-3 shadow">
                  <netvs-icon icon="edit"/>
                </b-button>
                <b-tooltip :target="'edit-ou-' +  ou.short_name" triggers="hover"
                           variant="primary" placement="bottom">
                  {{ $t('system.edit') }}
                </b-tooltip>
                <b-button variant="outline-secondary" :id="'show-evlog-ou-' + ou.short_name"
                          @click="show_ev_log(ou)" class="mt-3 shadow">
                  <netvs-icon icon="evlog"/>
                </b-button>
                <b-tooltip :target="'show-evlog-ou-' +  ou.short_name" triggers="hover"
                           variant="secondary" placement="bottom">
                  {{ $t('system.event_log') }}
                </b-tooltip>
              </b-button-group>
            </b-col>
          </b-row>
          <b-card header-class="p-0" body-class="p-0" class="mb-3 shadow-sm">
            <template v-slot:header>
              <b-button block variant="outline-secondary" v-b-toggle="ou.short_name + '-collapse'">
                {{ $t('views.org.ou.ou_administrators') }}
                <b-badge pill v-if="non_svc_mgrs">{{
                    non_svc_mgrs.length
                  }}
                </b-badge>
                <b-badge pill v-else>0</b-badge>
                <br/>
                <netvs-icon class="collapse-icon" icon="collapse"/>
              </b-button>
            </template>

            <b-collapse :id="ou.short_name + '-collapse'">
              <PaginatorTable :items="non_svc_mgrs" :fields="ou_admin_list_fields" class="m-0"
                              no-items-translation-key="views.org.ou.no_administrator_assigned">
                <template v-slot:cell(email)="data">
                  <b-link :href="`mailto:${mgrs_mail(data.item.mgr_login_name)}`">
                    {{ mgrs_by_login_name[data.item.mgr_login_name].email }}
                  </b-link>
                </template>
                <template v-slot:cell(name)="data">
                  <MgrLine :mgr="mgrs_by_login_name[data.item.mgr_login_name]" />
                  <template v-if="data.item.ou_short_name !== ou.short_name">
                    <b-badge variant="success" class="pr-sm-3"><netvs-icon icon="arrow_up_from_line" class="mr-1" />{{ $t('views.nd.ou_recursive_assigned') }}</b-badge>
                    <b-badge class="ml-n2" variant="secondary">{{ data.item.ou_short_name }}</b-badge>
                  </template>
                </template>
                <template v-slot:head(actions)>
                  <div :id="'button-assign-mgr-' +  ou.short_name">
                    <b-button @click="create_mgr_assignment(ou.short_name)" :id="'add_mgr_'+ou.short_name" block
                              variant="outline-success"
                              :disabled="!ou.is_mgr_assignable">
                      <netvs-icon icon="create"/>
                    </b-button>
                    <b-tooltip :target="'add_mgr_' +  ou.short_name" triggers="hover"
                               variant="success" placement="below" v-if="!ou.is_mgr_assignable">
                      {{ $t('views.org.ou.add_mgr_not_possible') }}
                    </b-tooltip>
                  </div>
                  <b-tooltip :target="'button-assign-mgr-' +  ou.short_name" triggers="hover"
                             variant="success" placement="left">
                    {{
                      ou.is_mgr_assignable ? $t('views.org.ou.assign_account') : $t('views.org.ou.assign_account_disabled')
                    }}
                  </b-tooltip>
                </template>
                <template v-slot:cell(actions)="data">
                  <b-button-group class="d-flex">
                    <b-button @click="delete_mgr_assignment(data.item)" variant="outline-danger"
                              :id="'button-unassign-mgr-' +  data.item.mgr_login_name">
                      <netvs-icon icon="unlink"/>
                    </b-button>
                    <b-tooltip :target="'button-unassign-mgr-' +  data.item.mgr_login_name" triggers="hover"
                               variant="danger" placement="left">
                      {{ $t('views.org.ou.remove_assignment') }}
                    </b-tooltip>
                  </b-button-group>
                </template>
              </PaginatorTable>
            </b-collapse>
          </b-card>
          <b-card header-class="p-0" body-class="p-0" class="mb-3 shadow-sm">
            <template v-slot:header>
              <b-button block variant="outline-secondary" v-b-toggle="ou.short_name + '-svc-collapse'">
                {{ $t('views.org.ou.ou_svc_accounts') }}
                <b-badge pill v-if="svc_mgrs">{{
                    svc_mgrs.length
                  }}
                </b-badge>
                <b-badge pill v-else>0</b-badge>
                <br/>
                <netvs-icon class="collapse-icon" icon="collapse"/>
              </b-button>
            </template>

            <b-collapse :id="ou.short_name + '-svc-collapse'">
              <PaginatorTable :items="svc_mgrs" :fields="ou_svc_list_fields" class="m-0"
                              no-items-translation-key="views.org.ou.no_svc_account_assigned">
                <template v-slot:cell(description)="data">
                  {{ mgrs_by_login_name[data.item.mgr_login_name].description || '' }}
                </template>
                <template v-slot:cell(mgr_login_name)="data">
                  <MgrLine :mgr="mgrs_by_login_name[data.item.mgr_login_name]" />
                  <template v-if="data.item.ou_short_name !== ou.short_name">
                    <b-badge variant="success" class="pr-sm-3"><netvs-icon icon="arrow_up_from_line" class="mr-1" />{{ $t('views.nd.ou_recursive_assigned') }}</b-badge>
                    <b-badge class="ml-n2" variant="secondary">{{ data.item.ou_short_name }}</b-badge>
                  </template>
                </template>
                <template v-slot:head(actions)>
                  <div :id="'button-assign-mgr-svc-' +  ou.short_name">
                    <b-button @click="create_mgr_assignment(ou.short_name)" :id="'add_mgr_svc_'+ou.short_name" block
                              variant="outline-success"
                              :disabled="!ou.is_mgr_assignable">
                      <netvs-icon icon="create"/>
                    </b-button>
                    <b-tooltip :target="'add_mgr_svc_' +  ou.short_name" triggers="hover"
                               variant="success" placement="below" v-if="!ou.is_mgr_assignable">
                      {{ $t('views.org.ou.add_mgr_not_possible') }}
                    </b-tooltip>
                  </div>
                  <b-tooltip :target="'button-assign-mgr-svc-' +  ou.short_name" triggers="hover"
                             variant="success" placement="left">
                    {{
                      ou.is_mgr_assignable ? $t('views.org.ou.assign_account') : $t('views.org.ou.assign_account_disabled')
                    }}
                  </b-tooltip>
                </template>
                <template v-slot:cell(actions)="data">
                  <b-button-group class="d-flex">
                    <b-button @click="delete_mgr_assignment(data.item)" variant="outline-danger"
                              :id="'button-unassign-mgr-' +  data.item.mgr_login_name">
                      <netvs-icon icon="unlink"/>
                    </b-button>
                    <b-tooltip :target="'button-unassign-mgr-' +  data.item.mgr_login_name" triggers="hover"
                               variant="danger" placement="left">
                      {{ $t('views.org.ou.remove_assignment') }}
                    </b-tooltip>
                  </b-button-group>
                </template>
              </PaginatorTable>
            </b-collapse>
          </b-card>
          <b-card header-class="p-0" body-class="p-0" class="mb-3 shadow-sm">
            <template v-slot:header>
              <b-button block variant="outline-secondary"
                        v-b-toggle="ou.short_name + '-collapse-groups'">
                {{ $tc('system.group', 2) }}
                <b-badge pill v-if="groups2ou[ou.short_name]">{{
                    groups2ou[ou.short_name].length
                  }}
                </b-badge>
                <b-badge pill v-else>0</b-badge>
                <br/>
                <netvs-icon class="collapse-icon" icon="collapse"/>
              </b-button>
            </template>
            <b-collapse :id="ou.short_name + '-collapse-groups'">
              <PaginatorTable :items="groups2ou[ou.short_name]" :fields="group_list_fields" class="m-0"
                              no-items-translation-key="views.org.ou.no_groups_assigned">
                <template v-slot:head(actions)>
                  <div :id="'create_group-' +  ou.short_name">
                    <b-button @click="create_group(ou.short_name)" block variant="outline-success">
                      <netvs-icon icon="create"/>
                    </b-button>
                  </div>
                  <b-tooltip :target="'create_group-' +  ou.short_name" triggers="hover"
                             variant="success" placement="left">
                    {{ $t('views.cntl.groups.create_group') }}
                  </b-tooltip>
                </template>
                <template v-slot:cell(actions)="">
                </template>
                <template v-slot:cell(name)="data">
                  <b-link :to="'/cntl/groups/'+data.item.name">{{ data.item.name }}</b-link>
                </template>
              </PaginatorTable>
              <template v-if="!is_entry && ous_by_parent && ou.short_name in ous_by_parent"
                        :items="groups2ou[ou.short_name]">
                <hr/>
                <h4 class="m-4">{{ $t('views.org.ou.groups_from_subordinated_ou') }}</h4>
                <PaginatorTable :items="non_ou_groups" :fields="group_list_fields" class="m-0">
                  <template v-slot:cell(actions)="">
                  </template>
                  <template v-slot:cell(name)="data">
                    <b-link :to="'/cntl/groups/'+data.item.name">{{ data.item.name }}</b-link>
                    <b-badge class="ml-2" pill :to="'/org/ou/' + data.item.ou_short_name">
                      <netvs-icon icon="orgs"/>
                      {{ data.item.ou_short_name }}
                    </b-badge>
                  </template>
                </PaginatorTable>
              </template>
            </b-collapse>
          </b-card>
          <b-card header-class="p-0" body-class="p-0" class="mb-3 shadow-sm">
            <template v-slot:header>
              <b-button block variant="outline-secondary"
                        v-b-toggle="ou.short_name + '-collapse-domains'">
                {{ $tc('system.domain', 2) }}
                <b-badge pill v-if="fqdns2ou[ou.short_name]">{{
                    fqdns2ou[ou.short_name].length
                  }}
                </b-badge>
                <b-badge pill v-else>0</b-badge>
                <br/>
                <netvs-icon class="collapse-icon" icon="collapse"/>
              </b-button>
            </template>
            <b-collapse :id="ou.short_name + '-collapse-domains'">
              <PaginatorTable :items="fqdns2ou[ou.short_name]" :fields="fqdn_list_fields" class="m-0"
                              no-items-translation-key="views.org.ou.no_domains_assigned">
                <template v-slot:head(actions)>
                  <b-button block variant="outline-success"
                            :id="'button-assign-fqdn-' +  ou.short_name"
                            @click="create_fqdn_assignment(ou.short_name)">
                    <netvs-icon icon="create"/>
                  </b-button>
                  <b-tooltip :target="'button-assign-fqdn-' +  ou.short_name" triggers="hover"
                             variant="success" placement="left">
                    {{ $t('views.org.ou.assign_domain') }}
                  </b-tooltip>
                </template>
                <template v-slot:cell(fqdn_value)="data">
                  {{ data.item.fqdn_value }}
                  <div v-if="data.item.ou_short_name !== ou.short_name">
                    <b-badge variant="success" class="pr-sm-3"><netvs-icon icon="arrow_up_from_line" class="mr-1" />{{ $t('views.nd.ou_recursive_assigned') }}</b-badge>
                    <b-badge class="ml-n2" variant="secondary">{{ data.item.ou_short_name }}</b-badge>
                  </div>
                  <b-badge variant="warning" v-if="data.item.ou_short_name === ou.short_name && data.item.is_recursive"><netvs-icon icon="arrow_down_from_line" class="mr-1" />{{ $t('views.nd.ou_recursive') }}</b-badge>
                </template>
                <template v-slot:cell(actions)="data">
                  <b-button-group class="d-flex">
                    <b-button @click="edit_fqdn_assignment(data.item)" variant="outline-primary"
                              :id="'button-edit-fqdn-' +  data.item.fqdn_value">
                      <netvs-icon icon="edit"/>
                    </b-button>
                    <b-tooltip :target="'button-edit-fqdn-' +  data.item.fqdn_value" triggers="hover"
                               variant="primary" placement="bottom">
                      {{ $t('views.org.ou.edit_assignment') }}
                    </b-tooltip>
                    <b-button @click="delete_fqdn_assignment(data.item)" variant="outline-danger"
                              :id="'button-unassign-fqdn-' +  data.item.fqdn_value">
                      <netvs-icon icon="unlink"/>
                    </b-button>
                    <b-tooltip :target="'button-unassign-fqdn-' +  data.item.fqdn_value" triggers="hover"
                               variant="danger" placement="bottom">
                      {{ $t('views.org.ou.remove_assignment') }}
                    </b-tooltip>
                  </b-button-group>
                </template>
              </PaginatorTable>
            </b-collapse>
          </b-card>
          <b-card header-class="p-0" body-class="p-0" class="shadow-sm">
            <template v-slot:header>
              <b-button block variant="outline-secondary"
                        v-b-toggle="ou.short_name + '-collapse-bcds'">
                {{ $t('views.org.ou.broadcast_domains_bcds') }}
                <b-badge pill v-if="bcds2ou[ou.short_name]">{{
                    bcds2ou[ou.short_name].length
                  }}
                </b-badge>
                <b-badge pill v-else>0</b-badge>
                <br/>
                <netvs-icon class="collapse-icon" icon="collapse"/>
              </b-button>
            </template>
            <b-collapse :id="ou.short_name + '-collapse-bcds'">
              <PaginatorTable :items="bcds2ou[ou.short_name]" :fields="bcd_list_fields" class="m-0"
                              no-items-translation-key="views.org.ou.no_bcds_assigned">
                <template v-slot:head(actions)>
                  <b-button block variant="outline-success"
                            :id="'button-assign-bcd-' +  ou.short_name"
                            @click="create_bcd_assignment(ou.short_name)">
                    <netvs-icon icon="create"/>
                  </b-button>
                  <b-tooltip :target="'button-assign-bcd-' +  ou.short_name" triggers="hover"
                             variant="success" placement="left">
                    {{ $t('views.org.ou.assign_bcd') }}
                  </b-tooltip>
                </template>
                <template v-slot:cell(name)="data">
                  <b-link :to="'/dnsvs/bcds/' + data.item.bcd_name" class="mr-2">{{ data.item.bcd_name }}</b-link>
                  <div v-if="data.item.ou_short_name !== ou.short_name">
                    <b-badge variant="success" class="pr-sm-3"><netvs-icon icon="arrow_up_from_line" class="mr-1" />{{ $t('views.nd.ou_recursive_assigned') }}</b-badge>
                    <b-badge class="ml-n2" variant="secondary">{{ data.item.ou_short_name }}</b-badge>
                  </div>
                  <b-badge variant="warning" v-if="data.item.ou_short_name === ou.short_name && data.item.is_recursive"><netvs-icon icon="arrow_down_from_line" class="mr-1" />{{ $t('views.nd.ou_recursive') }}</b-badge>
                </template>
                <template v-slot:cell(description)="item">
                  {{ bcds_by_name[item.item.bcd_name] ? bcds_by_name[item.item.bcd_name].description : '' }}
                </template>
                <template v-slot:cell(actions)="data">
                  <b-button-group class="d-flex">
                    <b-button @click="edit_bcd_assignment(data.item)" variant="outline-primary"
                              :id="'button-edit-bcd-' +  data.item.bcd_name">
                      <netvs-icon icon="edit"/>
                    </b-button>
                    <b-tooltip :target="'button-edit-bcd-' +  data.item.bcd_name" triggers="hover"
                               variant="primary" placement="bottom">
                      {{ $t('views.org.ou.edit_assignment') }}
                    </b-tooltip>
                    <b-button @click="delete_bcd_assignment(data.item)" variant="outline-danger"
                              :id="'button-unassign-bcd-' +  data.item.bcd_name">
                      <netvs-icon icon="unlink"/>
                    </b-button>
                    <b-tooltip :target="'button-unassign-bcd-' +  data.item.bcd_name" triggers="hover"
                               variant="danger" placement="bottom">
                      {{ $t('views.org.ou.remove_assignment') }}
                    </b-tooltip>
                  </b-button-group>
                </template>
              </PaginatorTable>
            </b-collapse>
          </b-card>
        </template>
      </Loading>
      <hr>
      <Loading :data="[ous, ous_by_parent]">
        <template v-if="ous && ous_by_parent">
          <div v-if="!ous_by_parent[ou.short_name] || ous_by_parent[ou.short_name].length === 0"
               class="font-italic text-center my-3">
            <span class="font-italic">{{ $t('views.org.ou.no_sub_ous') }}</span><br/>
          </div>
          <template v-else>
            <FilterInput v-model="filter"/>
            <div v-for="top_level_ou in ous_by_parent[ou.short_name]" :key="top_level_ou.short_name">
              <OUTreeEntry :ou="top_level_ou" :ous_by_parent="ous_by_parent" :edit_ou="edit_ou" :create_ou="create_ou" :can_create_ou="can_create_ou" :filter="filter"/>
            </div>
          </template>
        </template>
      </Loading>
    </template>
    <template v-else>
      <b-row>
        <b-col cols="6">
          <h1>{{ $tc('system.organizational_unit', 2) }}</h1>
          <b-checkbox :value="true" :unchecked-value="false" v-model="only_own_ou" switch>
            {{ $t('views.org.ou.show_only_own') }}
          </b-checkbox>
        </b-col>
        <b-col cols="6">
          <b-button-group class="float-right">
            <b-button variant="outline-success" @click="create_ou(null)" v-if="can_create_ou"><NetvsIcon icon="create" /></b-button>
          </b-button-group>
        </b-col>
      </b-row>
      <hr>
      <Loading :data="[ous, ous_by_parent]">
        <template v-if="ous && ous_by_parent">
          <div v-if="!root_ous || root_ous.length === 0"
               class="font-italic text-center my-3">
            <span class="font-italic">{{ $t('views.org.ou.no_ous') }}</span><br/>
          </div>
          <template v-else>
            <FilterInput v-model="filter"/>
            <div v-for="top_level_ou in root_ous" :key="top_level_ou.short_name">
              <OUTreeEntry :ou="top_level_ou" :ous_by_parent="ous_by_parent" :edit_ou="edit_ou" :create_ou="create_ou" :can_create_ou="can_create_ou" :filter="filter"/>
            </div>
          </template>
        </template>
      </Loading>
    </template>
    <DBEditor modal_id="assign_user_to_oe" object_function="create" object_fq_name="cntl.mgr2ou"
              :presets="db_editor_presets"
              :non_optionals_order="['ou_short_name', 'mgr_login_name']"
              :object_title="$t('views.org.ou.account_to_ou')"></DBEditor>
    <DBEditor modal_id="assign_fqdn_to_oe" :object_function="fqdn2ou_action" object_fq_name="dns.fqdn2ou"
              :old_data="fqdn2ou_db_editor_old_data"
              :presets="db_editor_presets"
              :non_optionals_order="fqdn2ou_db_editor_non_optionals"
              :object_title="$t('views.org.ou.domain_to_ou')"></DBEditor>
    <DBEditor modal_id="assign_bcd_to_oe" :object_function="bcd2ou_action" object_fq_name="nd.bcd2ou"
              :old_data="bcd2ou_db_editor_old_data"
              :presets="db_editor_presets"
              :non_optionals_order="bcd2ou_db_editor_non_optionals"
              :object_title="$t('views.org.ou.bcd_to_ou')"></DBEditor>
    <DBEditor modal_id="edit_oe" object_function="update" object_fq_name="org.unit"
              :non_optionals_order="['name', 'short_name']"
              :presets="db_editor_presets"
              :input_reducer="{type: ou_type_reducer}"
              :old_data="ou_to_edit"
              :object_title="ou_to_edit?ou_to_edit.name:''"
    ></DBEditor>
    <CreateOU modal_id="create_oe" :presets="db_editor_presets" />
    <DBEditor modal_id="create_group" object_function="create" object_fq_name="cntl.group"
              :non_optionals_order="['name', 'ou_short_name']"
              :presets="db_editor_presets"
              :input_reducer="{type: ou_type_reducer}"
    ></DBEditor>
    <EVLogViewer v-if="evlog_ou" modal_id="ou_evlog" ref_obj_fq="org.unit" refobj_id_field="short_name"
                 :refobj_id_value="evlog_ou.short_name"
                 :title="$tc('system.organizational_unit', 1) + ': ' + evlog_ou.name"></EVLogViewer>
  </div>
</template>

<script>
/**
 * @displayName OU
 * @description Organizational Unit View, shows the full organizational unit tree or a single organizational unit depending on the route
 */
import OUService from '@/api-services/ou.service'
import NativeOUService from '@/api-services.gen/org.unit'
import OUTypeService from '@/api-services.gen/org.unit_type'
import apiutil from '@/util/apiutil'
import transactionutil from '@/util/transactionutil'
import Mgr2OuService from '@/api-services.gen/cntl.mgr2ou'
import FQDN2OuService from '@/api-services.gen/dns.fqdn2ou'
import BCD2OuService from '@/api-services.gen/nd.bcd2ou'
import Loading from '@/components/Loading'
import DBEditor from '@/components/db-editor/APIObjectDBEditor.vue'
import EVLogViewer from '@/components/EVLogViewer'
import OUTreeEntry from '@/components/OUTreeEntry'
import PaginatorTable from '@/components/PaginatorTable'
import {EventBus} from '@/eventbus'
import FilterInput from '@/components/FilterInput.vue'
import NetvsIcon from '@/icons/NETVSIcon.vue'
import CreateOU from '@/db-editors/CreateOU.vue'
import MgrLine from '@/components/MgrLine.vue'

export default {
  name: 'OUOverview',
  components: { MgrLine, CreateOU, NetvsIcon, FilterInput, OUTreeEntry, EVLogViewer, DBEditor, Loading, PaginatorTable},
  data() {
    return {
      groups2ou: {},
      bcds_by_name: null,
      root_ous: null,
      ou: null,
      ou_to_edit: null,
      evlog_ou: null,
      only_own_ou: true,
      mgrs2ou: null,
      bcds2ou: null,
      fqdns2ou: null,
      mgrs_by_login_name: null,
      ou_type_reducer: {},
      is_entry: null,
      ous: null,
      ous_by_name: null,
      ous_by_parent: null,
      filter: '',
      db_editor_presets: {},
      fqdn2ou_db_editor_old_data: null,
      fqdn2ou_db_editor_non_optionals: ['ou_short_name', 'fqdn_value'],
      fqdn2ou_action: 'create',
      bcd2ou_db_editor_old_data: null,
      bcd2ou_db_editor_non_optionals: ['ou_short_name', 'bcd_value'],
      bcd2ou_action: 'create',
      outypes_by_shortname: {},
      create_ou_parent: null
    }
  },
  watch: {
    $route() {
      this.ou = this.ous = null
      this.refresh()
    },
    only_own_ou: {
      immediate: true,
      async handler() {
        this.ous = null
        this.ous_by_parent = null
        await this.refresh()
      }
    }
  },
  computed: {
    can_create_ou() {
      return apiutil.checkPermission(this.$store.state, 'org.rw:unit')
    },
    non_svc_mgrs() {
      return this.mgrs2ou[this.ou.short_name].filter(m => !this.is_svc(m.mgr_login_name))
    },
    svc_mgrs() {
      return this.mgrs2ou[this.ou.short_name].filter(m => this.is_svc(m.mgr_login_name))
    },
    non_ou_groups() {
      if (!this.ou) return []
      const flattened = []
      for (const g of Object.values(this.groups2ou)) {
        flattened.push(...g)
      }
      return flattened.filter(g => g.ou_short_name !== this.ou.short_name)
    },
    bcd_list_fields() {
      return [
        {
          key: 'name',
          label: this.$tc('system.bcd', 1),
          sortable: true
        },
        {
          key: 'description',
          label: this.$t('system.description'),
          sortable: true
        },
        {
          key: 'actions',
          label: this.$tc('system.action', 2),
          sortable: false
        }
      ]
    },
    fqdn_list_fields() {
      return [
        {
          key: 'fqdn_value',
          label: this.$t('system.fqdn'),
          sortable: true
        },
        {
          key: 'actions',
          label: this.$tc('system.action', 2),
          sortable: false
        }
      ]
    },
    group_list_fields() {
      return [
        {
          key: 'name',
          label: this.$t('system.name'),
          sortable: true
        },
        {
          key: 'actions',
          label: this.$tc('system.action', 2),
          sortable: false
        }
      ]
    },
    ou_admin_list_fields() {
      return [
        {
          key: 'name',
          label: this.$t('system.name'),
          sortable: false
        },
        {
          key: 'email',
          label: this.$t('system.email'),
          sortable: false
        },
        {
          key: 'actions',
          label: this.$tc('system.action', 2),
          sortable: false
        }
      ]
    },
    ou_svc_list_fields() {
      return [
        {
          key: 'mgr_login_name',
          label: this.$t('system.name'),
          sortable: false
        },
        {
          key: 'description',
          label: this.$t('system.description'),
          sortable: false
        },
        {
          key: 'actions',
          label: this.$tc('system.action', 2),
          sortable: false
        }
      ]
    }
  },
  methods: {
    check_rights: apiutil.checkPermission,
    async refresh() {
      this.is_entry = null
      this.ou = null
      this.mgrs_by_login_name = null
      let ou_name_req = this.$route.params.ou || null
      this.mgrs = this.mgrs2ou = this.ous = null
      this.outypes_by_shortname = null
      let res = null
      if (ou_name_req) {
        this.is_entry = true
        const ou_types = (await OUTypeService.list(this.$store.state, {sorting_params_list: ['position']})).data[0]
        for (const t of ou_types) {
          this.ou_type_reducer[t.short_name] = {display_name: t.name}
        }
        ou_name_req = ou_name_req.toUpperCase()
        res = (await OUService.getOU(this.$store.state, ou_name_req)).data
        const parent_ous = res.parent_ous
        const bread = []
        for (let i = 1; i < parent_ous.length; i++) {
          bread.push({text: parent_ous[i].short_name, to: `/org/ou/${parent_ous[i].short_name}`})
        }
        bread.push('/org/ou')
        EventBus.$emit('overwrite_breadcrumbs', function () {
          return bread
        })
        this.mgrs2ou = apiutil.dict_of_lists_by_value_of_array(res.mgr2ou_list, 'ou_short_name')
        this.mgrs2ou[ou_name_req] = (this.mgrs2ou[ou_name_req] || []).concat(res.recursive_mgr2ou_list.filter(f => f.ou_short_name !== ou_name_req))
        this.mgrs_by_login_name = apiutil.dict_by_value_of_array(res.mgr_list.concat(res.recursive_mgr_list), 'login_name')
        this.fqdns2ou = apiutil.dict_of_lists_by_value_of_array(res.fqdn2ou_list, 'ou_short_name')
        this.fqdns2ou[ou_name_req] = (this.fqdns2ou[ou_name_req] || []).concat(res.recursive_fqdn2ou_list.filter(f => f.ou_short_name !== ou_name_req))
        this.bcds2ou = apiutil.dict_of_lists_by_value_of_array(res.bcd2ou_list, 'ou_short_name')
        this.bcds2ou[ou_name_req] = (this.bcds2ou[ou_name_req] || []).concat(res.recursive_bcd2ou_list.filter(b => b.ou_short_name !== ou_name_req))
        this.outypes_by_shortname = apiutil.dict_by_value_of_array(res.unit_type_list, 'short_name')
        this.ous = res.child_ous
        if (this.ous.length === 0) {
          this.ou = null
          return
        }
        this.ous_by_name = apiutil.dict_by_value_of_array(res.current_ou, 'short_name')
        this.ou = this.ous_by_name[ou_name_req]
        this.$store.commit('setNavigationRefreshHandle', {gpk: this.ou.gpk, objType: 'org.unit'})
        this.groups2ou = apiutil.dict_of_lists_by_value_of_array(res.group_list, 'ou_short_name')
        this.bcds_by_name = apiutil.dict_by_value_of_array(res.bcd_list, 'name')
      } else {
        this.is_entry = false
        if (this.only_own_ou) {
          res = (await OUService.listOwn(this.$store.state)).data
          this.mgrs2ou = apiutil.dict_of_lists_by_value_of_array(res.mgr2ou_list, 'ou_short_name')
          this.fqdns2ou = apiutil.dict_of_lists_by_value_of_array(res.fqdn2ou_list, 'ou_short_name')
          this.bcds2ou = apiutil.dict_of_lists_by_value_of_array(res.bcd2ou_list, 'ou_short_name')
          this.mgrs_by_login_name = apiutil.dict_by_value_of_array(res.mgr_list, 'login_name')
          this.ous = res.unit_list_superset
          this.root_ous = res.unit_list
          this.outypes_by_shortname = apiutil.dict_by_value_of_array(res.unit_type_list, 'short_name')
        } else {
          res = (await OUService.listAll(this.$store.state)).data
          this.mgrs2ou = apiutil.dict_of_lists_by_value_of_array(res.mgr2ou_list, 'ou_short_name')
          this.mgrs_by_login_name = apiutil.dict_by_value_of_array(res.mgr_list, 'login_name')
          this.fqdns2ou = apiutil.dict_of_lists_by_value_of_array(res.fqdn2ou_list, 'ou_short_name')
          this.bcds2ou = apiutil.dict_of_lists_by_value_of_array(res.bcd2ou_list, 'ou_short_name')
          this.ous = res.unit_list
          this.root_ous = apiutil.dict_of_lists_by_value_of_array(this.ous, 'tree_level')['0']
          this.outypes_by_shortname = apiutil.dict_by_value_of_array(res.unit_type_list, 'short_name')
        }
      }
      this.ous_by_parent = apiutil.dict_of_lists_by_value_of_array(this.ous, 'parent_short_name')
      for (const item_ou_group in this.ous_by_parent) {
        this.ous_by_parent[item_ou_group].sort((a, b) => a.sub_position - b.sub_position)
      }
    },
    edit_ou(ou) {
      this.ou_to_edit = this.db_editor_presets = ou
      this.$root.$emit('bv::show::modal', 'edit_oe')
    },
    create_ou(parent_ou) {
      this.create_ou_parent = parent_ou
      if (parent_ou) {
        this.db_editor_presets = {parent_short_name: parent_ou.short_name}
      } else {
        this.db_editor_presets = {}
      }
      this.$root.$emit('bv::show::modal', 'create_oe')
    },
    create_group(ou) {
      this.db_editor_presets = {ou_short_name: ou}
      this.$root.$emit('bv::show::modal', 'create_group')
    },
    create_mgr_assignment(ou) {
      this.db_editor_presets = {ou_short_name: ou}
      this.$root.$emit('bv::show::modal', 'assign_user_to_oe')
    },
    create_fqdn_assignment(ou) {
      this.fqdn2ou_action = 'create'
      this.db_editor_old_data = null
      this.fqdn2ou_db_editor_non_optionals = ['ou_short_name', 'fqdn_value']
      this.db_editor_presets = {ou_short_name: ou}
      this.$root.$emit('bv::show::modal', 'assign_fqdn_to_oe')
    },
    create_bcd_assignment(ou) {
      this.db_editor_presets = {ou_short_name: ou}
      this.bcd2ou_action = 'create'
      this.db_editor_old_data = null
      this.bcd2ou_db_editor_non_optionals = ['ou_short_name', 'bcd_name']
      this.$root.$emit('bv::show::modal', 'assign_bcd_to_oe')
    },
    delete_ou(item) {
      const ta = transactionutil.generateDeleteElement('org.unit', NativeOUService.deleteParamsList(), item, item.name + ' (' + item.short_name + ')')
      this.$store.commit('addTransactionElement', ta)
      this.$emit('commited', ta)
      if (!this.isMobile() && !this.$store.state.show_sidebar_right) {
        this.$store.commit('showSidebarRight', true)
      }
    },
    delete_mgr_assignment(item) {
      const ta = transactionutil.generateDeleteElement('cntl.mgr2ou', Mgr2OuService.deleteParamsList(), item, item.mgr_login_name + ' ' + this.$t('views.org.ou.from_ou') + ' ' + item.ou_short_name)
      this.$store.commit('addTransactionElement', ta)
      this.$emit('commited', ta)
      if (!this.isMobile() && !this.$store.state.show_sidebar_right) {
        this.$store.commit('showSidebarRight', true)
      }
    },
    delete_bcd_assignment(item) {
      const ta = transactionutil.generateDeleteElement('nd.bcd2ou', BCD2OuService.deleteParamsList(), item, item.bcd_name + ' ' + this.$t('views.org.ou.from_ou') + ' ' + item.ou_short_name)
      this.$store.commit('addTransactionElement', ta)
      this.$emit('commited', ta)
      if (!this.isMobile() && !this.$store.state.show_sidebar_right) {
        this.$store.commit('showSidebarRight', true)
      }
    },
    delete_fqdn_assignment(item) {
      const ta = transactionutil.generateDeleteElement('dns.fqdn2ou', FQDN2OuService.deleteParamsList(), item, item.fqdn_value + ' ' + this.$t('views.org.ou.from_ou') + ' ' + item.ou_short_name)
      this.$store.commit('addTransactionElement', ta)
      this.$emit('commited', ta)
      if (!this.isMobile() && !this.$store.state.show_sidebar_right) {
        this.$store.commit('showSidebarRight', true)
      }
    },
    edit_fqdn_assignment(item) {
      this.fqdn2ou_action = 'update'
      this.fqdn2ou_db_editor_old_data = item
      this.fqdn2ou_db_editor_non_optionals = ['ou_short_name']
      this.db_editor_presets = item
      this.$root.$emit('bv::show::modal', 'assign_fqdn_to_oe')
    },
    edit_bcd_assignment(item) {
      this.bcd2ou_action = 'update'
      this.bcd2ou_db_editor_old_data = item
      this.bcd2ou_db_editor_non_optionals = ['ou_short_name']
      this.db_editor_presets = item
      this.$root.$emit('bv::show::modal', 'assign_bcd_to_oe')
    },
    show_ev_log(ou) {
      this.evlog_ou = ou
      this.$root.$nextTick(() => {
        this.$root.$emit('bv::show::modal', 'ou_evlog')
      })
    },
    mgrs_mail(user_name) {
      return this.mgrs_by_login_name[user_name].first_name + ' ' + this.mgrs_by_login_name[user_name].last_name +
          '<' + this.mgrs_by_login_name[user_name].email + '>'
    },
    is_svc(user_name) {
      if (user_name in (this.mgrs_by_login_name || {})) {
        return this.mgrs_by_login_name[user_name].is_svc
      }
      return false
    }
  },
  async mounted() {
    await this.refresh()
  }
}
</script>

<style scoped>

</style>
